<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <filter-select
            v-model="filter.companyId"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('company')"
            :options="companies"
          />
        </div>
        <div class="col-md-6">
          <base-input v-model="filter.name" :label="$t('name')" />
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <sub-header :title="$t('listOfOffices')" />
      </div>
      <div class="row office-list">
        <div
          v-for="(location, index) in locations"
          :key="index"
          class="col-4 mb-4 text-center"
        >
          <p class="m-0">
            {{ location.name }}
          </p>
          <b-form-checkbox
            v-model="location.selected"
            size="lg"
            switch
          />
        </div>
      </div>
    </div>
    <div class="col-md-12 text-right mt-5">
      <hr>
      <base-filled-button
        bg-color="#707070"
        class="float-left mx-0"
        icon-class="far fa-times-circle"
        :on-click="() => $emit('close')"
        :text="$t('cancel')"
      />
      <button class="btn btn-primary" @click="onSubmit()">
        <i class="fal fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import SubHeader from '@/components/SubHeader';
import {
  getAssignedLocations as _getAssignedLocations,
  assignLocations as _assignLocations
} from '@/services/BreakManagerService';

export default {
  name: 'AssignOffices',
  components: {
    SubHeader,
    BaseInput,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    breakTypeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filter: {
        companyId: null,
        name: null,
      },
      allLocations: [],
    };
  },
  async mounted() {
    await _getAssignedLocations(this.breakTypeId).then((response) => {
      this.allLocations = response.data;
    });
  },
  computed: {
    locations() {
      let locations = this.allLocations;
      if (this.filter.companyId)
        locations = locations.filter(
          (x) => x.companyId == this.filter.companyId,
        );

      if (this.filter.name)
        locations = locations.filter(
          (x) =>
            x.name.toLowerCase().indexOf(this.filter.name.toLowerCase()) !== -1,
        );

      return locations;
    },
  },
  methods: {
    async onSubmit() {
      await _assignLocations({
        breakTypeId: this.breakTypeId,
        locations: this.allLocations.filter(x => x.selected).map(x => x.id)
      })
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    }
  }
};
</script>
<style lang="scss" scoped>
.office-list {
  min-height: 300px;
  height: 600px;
  overflow-y: scroll;
}
</style>
