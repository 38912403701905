<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('implicit') }}: </label>
          <span>{{ $t(value.isImplicit ? 'yes' : 'no') }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="cell">
          <label>{{ $t('finalStep') }}: </label>
          <span>{{ $t(value.isFinal ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('available') }}: </label>
          <span>{{ $t(value.isActive ? 'yes' : 'no') }}</span>
        </div>
      </div>

      <div class="col-12 col-sm-4">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'BreakSearchTypeResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit'),
        },
        {
          label: this.$t('assignOffice'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => this.$emit('on-assign'),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
