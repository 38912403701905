<template>
  <section>
    <content-header v-if="showMaintenance" :title="$t('breakMaintenance')" />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterBreak')" />
        </div>
        <div class="row">
          <div v-if="!isMultiOffice" class="col-md-3">
            <filter-select
              v-model="filter.companyId"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('company')"
              :options="collections.companies"
            />
          </div>
          <div v-if="!isMultiOffice" class="col-md-3">
            <filter-select
              v-model="filter.locationConfigurationId"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('location')"
              :options="collections.locations"
            />
          </div>
          <div :class="!isMultiOffice ? 'col-md-3' : 'col'">
            <base-input v-model="filter.name" :label="$t('name')" />
          </div>
          <div v-if="!isMultiOffice" class="col-md-3 pt-2 text-center">
            <b-form-checkbox
              class="mt-3"
              v-model="filter.isLocationConfiguration"
              switch
              :disabled="!filter.locationConfigurationId"
              size="lg"
            >
              {{ $t('configureLocation') }}
            </b-form-checkbox>
          </div>
          <div class="col text-right mt-4">
            <base-filled-button
              class="mx-0"
              icon-class="fa fa-search"
              :on-click="() => getBreaks(1)"
              :text="$t('filter')"
            />
          </div>
        </div>
      </div>

      <separator class="my-4" :text="$t('listOfBreak')" />
      <div v-if="!isLocationConfiguration" class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => openFormModal()"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(item, index) in breaks.data"
          :key="index"
          class="mt-3 col-12"
        >
          <component
            :is="`Search${isLocationConfiguration ? 'Location' : 'Type'}Result`"
            :value="item"
            :index="index"
            :index-in-edit-mode="indexInEditMode"
            @on-edit="edit(item, index)"
            @on-delete="openDeleteModal(item)"
            @on-assign="openAssignModal(item)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="breaks.count > 0"
        id="pgPager"
        class="my-4"
        :data="breaks"
        :page-size="pageSize"
        @pagination-go-page="getBreaks($event)"
        @pagination-rows-per-page="getBreaks(pageIndex, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
     <CustomModal
      v-model="showBreakFormModal"
      size="lg"
      :title="$t(!model.breakTypeId  ? 'createBreak' : 'updateBreak')"
    >
      <BreakTypeForm
        v-if="showBreakFormModal"
        :value="model"
        @close="showBreakFormModal = false"
        @submitted="getBreaks()"
      />
    </CustomModal>
     <CustomModal
      v-model="showAssignModal"
      :title="$t('assignOffice')"
    >
      <AssignOffices
        v-if="showAssignModal"
        :break-type-id="model.breakTypeId"
        :companies="collections.companies"
        @close="showAssignModal = false"
        @submitted="getBreaks()"
      />
    </CustomModal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="breakNameIsNotCorrect"
      :item="model"
      label="breakName"
      name="name"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import Separator from '@/components/Separator';
import { modulesName } from '@/store';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import SearchTypeResult from './components/SearchTypeResult';
import SearchLocationResult from './components/SearchLocationResult';
import BreakTypeForm from './components/BreakTypeForm.vue';
import {
  getBreaks as _getBreaks,
  getBreakLocations as _getBreakLocations,
  deleteBreakType as _deleteBreakType
} from '@/services/BreakManagerService';
import { mapGetters } from 'vuex';
import BaseInput from '@/components/BaseInput.vue';
import DeleteModal from '@/components/DeleteModal';
import CustomModal from '@/components/basics/modal/CustomModal';
import AssignOffices from './components/AssignOffices';

export default {
  name: 'Breaks',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchTypeResult,
    SearchLocationResult,
    contentHeader,
    SubHeader,
    Separator,
    AlertMessage,
    BigLoader,
    BaseInput,
    BreakTypeForm,
    CustomModal,
    DeleteModal,
    AssignOffices
  },
  data() {
    return {
      collections: {
        locations: [],
        companies: [],
      },
      filter: {
        companyId: null,
        locationConfigurationId: null,
        name: null,
        isLocationConfiguration: false,
      },
      breaks: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      selectedBreak: null,
      showMaintenance: false,
      loading: false,
      isLocationConfiguration: false,
      indexInEditMode: null,
      model: {},
      showBreakFormModal: false,
      showAssignModal: false
    };
  },
  mounted() {
    if (this.isMultiOffice) {
      this.isLocationConfiguration = true;
      this.filter.isLocationConfiguration = true;
    }
    this.getCompanies();
    this.showMaintenance = true;
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      setTimeout(() => {
        this.filter.name = null;
        this.breaks = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
    'filter.companyId'(value) {
      this.collections.locations = [];
      this.filter.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
    'filter.locationConfigurationId'(value) {
      if (!value) return (this.filter.isLocationConfiguration = false);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.collections.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getBreaks(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      let locationConfigurationId = this.filter.locationConfigurationId;

      if (this.isMultiOffice)
        locationConfigurationId = Number(
          this.currentActiveLocation.locationConfigurationId,
        );

      const payload = {
        CompanyId: this.filter.companyId,
        LocationConfigurationId: locationConfigurationId,
        Name: this.filter.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      if (
        this.isLocationConfiguration !== this.filter.isLocationConfiguration
      ) {
        this.breaks = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
      }

      this.isLocationConfiguration = this.filter.isLocationConfiguration;

      const getBreaks = this.isLocationConfiguration
        ? _getBreakLocations
        : _getBreaks;

      await getBreaks(payload)
        .then(({ data }) => {
          this.breaks = data;
          this.noResultsFound = !this.breaks.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    edit(item, index) {
      if (this.isLocationConfiguration)
        this.indexInEditMode = this.indexInEditMode != index ? index : null;
      else {
        this.model = {...item};
        this.showBreakFormModal = true;
      }
    },
    openFormModal() {
      this.model = {
        name: null,
        isImplicit: false,
        isFinal: false,
        isActive: true
      };
      this.showBreakFormModal = true;
    },
    openDeleteModal(breakType) {
      this.model = breakType;
      this.showDeleteModal = true;
    },
    onDelete() {
      _deleteBreakType(this.model.breakTypeId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getBreaks();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openAssignModal(breakType) {
      this.model = breakType;
      this.showAssignModal = true;
    }
  },
};
</script>
