<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="cell">
          <label>{{ $t('breakName') }}: </label>
          <span>{{ value.name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('breakTime') }}: </label>
          <span v-if="!isEditMode">{{ value.breakTime || '-' }}</span>
          <filter-numeric
            v-if="isEditMode"
            v-model="model.breakTime"
            :min="0"
          />
        </div>
        <div class="cell">
          <label>{{ $t('deleteVisitRelationship') }}: </label>
          <span v-if="!isEditMode">{{ $t(value.clearTurnVisiting ? 'yes' : 'no')  }}</span>
          <b-form-checkbox
            v-if="isEditMode"
            class="float-left ml-2"
            v-model="model.clearTurnVisiting"
            switch
          >
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="cell">
          <label>{{ $t('documentBreak') }}: </label>
          <span v-if="!isEditMode"> {{ $t(value.haveDocumentation ? 'yes' : 'no') }}</span>
          <b-form-checkbox
            v-if="isEditMode"
            class="float-left ml-2"
            v-model="model.haveDocumentation"
            switch
          >
          </b-form-checkbox>
        </div>
        <div class="cell">
          <label>{{ $t('autoApproved') }}: </label>
          <span v-if="!isEditMode">{{ $t(value.autoApproved ? 'yes' : 'no') }}</span>
          <b-form-checkbox
            v-if="isEditMode"
            class="float-left ml-2"
            v-model="model.autoApproved"
            switch
          >
          </b-form-checkbox>
        </div>
        <div class="cell">
          <label>{{ $t('available') }}: </label>
          <span v-if="!isEditMode">{{ $t(value.isActive ? 'yes' : 'no') }}</span>
          <b-form-checkbox
            v-if="isEditMode"
            class="float-left ml-2"
            v-model="model.isActive"
            switch
          >
          </b-form-checkbox>
        </div>
      </div>

      <div class="col-12 col-sm-4">
        <action-button v-if="!isEditMode" :actions="actions" :text="$t('actions')" />
         <button
          v-if="isEditMode"
          class="btn btn-primary"
          style="width: 140px"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
        <br />
        <button
          v-if="isEditMode"
          class="btn mt-2"
          style="background-color: #707070; width: 140px; color: white"
          @click="$emit('on-edit', null)"
        >
          <i class="fas fa-ban" /> {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import {
  updateBreakLocation as _updateBreakLocation
} from '@/services/BreakManagerService';

export default {
  name: 'BreakSearchTypeResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null
    },
    indexInEditMode: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      model: {},
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.edit(),
        },
      ],
    };
  },
  computed: {
    isEditMode() {
      return this.index === this.indexInEditMode;
    }
  },
  methods: {
    edit() {
      this.$emit('on-edit', this.index);
      this.model = { ...this.value };
    },
    async onSubmit() {
      await _updateBreakLocation({...this.model, breakTime: this.model.breakTime || 0}).then(() => {
        this.ShowSuccessSaveToast();
        this.value.breakTime = this.model.breakTime || 0;
        this.value.clearTurnVisiting = this.model.clearTurnVisiting;
        this.value.haveDocumentation = this.model.haveDocumentation;
        this.value.autoApproved = this.model.autoApproved;
        this.value.isActive = this.model.isActive;

        this.$emit('on-edit', null);
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
</style>
