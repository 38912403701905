<template>
  <div class="col-md-12">
    <validation-observer ref="formRef" novalidate>
      <div class="row">

        <div class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required|max:100">
            <base-input
              v-model="value.name"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="$t('name')"
            />
          </validation-provider>
        </div>
        <div class="col-md-4">
          <b-form-checkbox
            v-model="value.isImplicit"
            switch
          >
          {{ $t('implicit') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-4 text-center">
          <b-form-checkbox
            v-model="value.isFinal"
            switch
          >
          {{ $t('finalStep') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-4 text-right">
          <b-form-checkbox
            v-model="value.isActive"
            switch
          >
          {{ $t('available') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-12 text-right mt-5">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />

          <button
            class="btn btn-outline-primary"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  addBreakType as _addBreakType,
  updateBreakType as _updateBreakType
} from '@/services/BreakManagerService';

export default {
  name: 'BreakTypeForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;
      const request = this.value.breakTypeId ? _updateBreakType : _addBreakType;

      await request(this.value).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>
